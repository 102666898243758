import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './GameStats.css';
import { Link } from 'gatsby';

//Components
import ToolTip from '../../Global/Tooltip/Tooltip';

const GameStats = (props) => {
  const {
    data,
    toolTipData,
    loadToolTip,
    Xposition,
    Yposition,
    isLoaded
  } = props;
	return (
    <ComponentWrapper className="c-GameStats">
      <h4>Game Stats</h4>
      <div className="grid-wrapper">
        <div className="grid-container">
          <div className="matchup">Matchup</div>
          <div className="result">Result</div>
          <div className="role">Role</div>
          <div className="god">God</div>
          <div className="final-build icon">K / D / A</div>
          <div className="final-build icon">Gold</div>
          <div className="relics">Relics</div>
          <div className="final-build">Final Build</div>

          {data.map((item, index) =>
            <React.Fragment key={item.matchup + index}>
              <Link to={`/matches/${item.match_id}`}>
                <div className="item">VS
                <span>{item.matchup},</span>
                  {item.game_date &&new Date(item.game_date*1000).toLocaleDateString(navigator.language, {month: 'short', day: 'numeric'})}
                </div>
              </Link>
              <div className="item">{item.win_status === '0' ? "Loss" : "Win"}</div>
              <div className="item">{item.role}</div>
              <div className="item">
                <img src={`https://webcdn.hirezstudios.com/smite/god-icons/${item.god.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s+/g, "-")}.jpg`} alt={item.god} />
                {item.god}
              </div>
              <div className="item icon">{item.stats.kills} / {item.stats.deaths} / {item.stats.assists}</div>
              <div className="item icon">{Number(item.stats.gold_earned)?Number(item.stats.gold_earned).toLocaleString():item.stats.gold_earned}</div>
              <div className="item">
                <ul className='relic-items'>
                  {item.relics.map((relic, index) =>(
                    <li key={relic + index}>
                      {relic !== 'null' &&
                        <img 
                          className='relic-item'
                          onMouseOver={(e) => loadToolTip(relic, e.clientX, e.clientY, true)}
                          onMouseOut={() => loadToolTip(null, 0, 0, false)}
                          src={`https://webcdn.hirezstudios.com/smite/item-icons/${relic.toLowerCase().replace(/[^a-zA-Z- ]/g, "").replace(/\s+/g, '-')}.jpg`} 
                          alt={relic} 
                        />
                      }
                    </li>
                  ))}
                </ul>
              </div>
              <div className="item">
                <ul className='build-items'>
                  {item.build.map((buildItem, index) =>(
                    <li key={buildItem + index}>
                      {buildItem !== 'null' &&
                        <img 
                          className='build-item'
                          onMouseOver={(e) => loadToolTip(buildItem, e.clientX, e.clientY, true)}
                          onMouseOut={() => loadToolTip(null, 0, 0, false)}
                          src={`https://webcdn.hirezstudios.com/smite/item-icons/${buildItem.toLowerCase().replace(/[^a-zA-Z- ]/g, "").replace(/\s+/g, '-')}.jpg`} 
                          alt={buildItem} 
                        />
                      }
                    </li>
                  ))}
                </ul>
              </div>
              </React.Fragment>
          )}
        </div>
        {isLoaded &&
          <ToolTip
            toolTipData={toolTipData}
            Xposition={Xposition}
            Yposition={Yposition}
          />
        }
      </div>
    </ComponentWrapper>
	); 
};

GameStats.propTypes = {
  data: PropTypes.array.isRequired,
  toolTipData: PropTypes.object,
  loadToolTip: PropTypes.func,
  Xposition: PropTypes.number,
  Yposition: PropTypes.number,
  isLoaded: PropTypes.bool
}

export default GameStats;