import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: var(--background);
  border: 1px solid var(--border);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  max-width: 81.8rem;
  overflow: hidden !important;
  width: 100%;
  h4 {
    border-bottom: .1rem solid var(--border);
    font-family: var(--font-body);
    font-size: 1.8rem;
    font-weight: bold;
    padding: 1.5rem 2rem;
  }
  .grid-wrapper {
    overflow: hidden;
    @media only screen and (max-width: 768px) {
      overflow-x: scroll;
    }
    .grid-container {
      align-items: center;
      color: var(--background);
      display: inline-grid;
      grid-template-columns: repeat(7, 1fr) 2fr;
      grid-template-rows: 3rem;
      font-weight: 600;
      justify-content: center;
      text-transform: uppercase;
      .item {
        align-items: center;
        border-bottom: 1px solid var(--border);
        color: var(--text);
        display: flex;
        font-size: 1.3rem;
        font-weight: 300;
        min-height: 3.5rem;
        padding: 0.5rem 1rem;
        white-space:nowrap;
        .build-items, .relic-items {
          display: inline-flex;
          list-style: none;
        }
        span {
          font-weight: 600;
          padding: 0 0.5rem;
        }
        img {
          height: 3rem;
          width: 3rem;
          padding: 0.25rem;
        }
        
      }
      .item.stat{
          justify-content: flex-end;
          text-align: right;
        }
      .god, .icon {
        align-items: center;
        justify-content: center;
        img {
          height: 3rem;
          width: 2.5rem;
          padding: 0 0.25rem;
        }
      }
      .matchup, .final-build, .god, .relics, .result, .role {
        align-items: center;
        background: var(--primary);
        display: inline-flex;
        padding: .25rem 1rem;
        height: 100%;
        &.kda {
          padding: 1.1rem 0;
        }
      }
      .stat-number{
        justify-content: flex-end;
        text-align: right;
      }
    }
  }`