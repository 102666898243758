import React, { Component } from 'react';
// import { Link } from 'gatsby';
import withContext from 'store/withContext';
import { format } from 'date-fns';


//Components
import StatsTable from 'components/Global/StatsTable';
import TopGodsCard from 'components/Players/TopGodsCard';
import GameStats from 'components/Players/GameStats';
import PlayerHeader from 'components/Players/PlayerHeader';


import PageWrapper from '../styles/player.css';
// import { isThisSecond } from 'date-fns';

class Player extends Component {

  constructor(props) {
    super(props);
    this.state = {
      playerData: null,
      hide: 'Show',
      toolTipData: {},
      Xposition: null,
      Yposition: null,
      isLoaded: false
    }
  }

  getPlayer = (playerName) => {
    fetch(`${this.props.context.baseUrl}/player/${playerName}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          playerData: response
        });
      });
  }

  toggleStats = () => {
    if (this.state.hide === 'Hide') {
      this.setState({
        hide: 'Show'
      })
    } else {
      this.setState({
        hide: 'Hide'
      })
    }
  }

  getItemDetails = () => {
    fetch("https://cms.smitegame.com/wp-json/smite-api/getItems/1")
      .then(response => response.json())
      .then(response => {
      this.setState({
        itemDetails: response
      });
    });
  }

  loadToolTip = (name, Xposition, Yposition, isLoaded) => {
    this.setState({
      isLoaded: isLoaded
    })
    this.state.itemDetails.find(item => {
      if (item.DeviceName === name) {
        this.setState({
          toolTipData: item,
          Xposition: Xposition,
          Yposition: Yposition,
        })
      }
    })
  }

  componentDidMount() {
    this.getPlayer(this.props.location.pathname.split('/')[2]);
    this.getItemDetails();
  }

  render() {
    return (
      <PageWrapper className="player-page">
        <div className="center-wrapper">
          {this.state.playerData &&
            <PlayerHeader
              banner_image={`https://webcdn.hirezstudios.com/smite-esports/dev/player-backgrounds/${this.state.playerData.game_name.toLowerCase().replace(/\s+/g, "-")}.png`}
              game_name={this.state.playerData.game_name}
              name={this.state.playerData.name}
              role={this.state.playerData.role}
              links={this.state.playerData.links}
              team={this.state.playerData.team}
            />
          }
          <div className="component-wrapper">
            <div className="header-copy">
              <h2>Player Stats - Current Season</h2>
              <div className="stats-btn" onClick={this.toggleStats}>{this.state.hide} Previous Seasons</div>
            </div>
            <div className="stats-tables">
              <div className="stats-container">
                {(this.state.playerData && this.state.playerData.role !== 'Coach') &&
                  <>
                    <GameStats
                      data={this.state.playerData.recent_builds.slice(0,6)}
                      loadToolTip={this.loadToolTip}
                      toolTipData={this.state.toolTipData}
                      Xposition={this.state.Xposition}
                      Yposition={this.state.Yposition}
                      isLoaded={this.state.isLoaded}
                    />
                    {console.log('redrawing player build')}
                  </>
                }
              </div>
            </div>
            <div className={`additional-stats ${this.state.hide === "Hide" ? "" : "hide"}`}>
              <h2>Previous Seasons Stats</h2>
              <div className="additional-stats-container">
                <StatsTable
                  headings={{season: 'Season', team: 'Team', role: 'Role', kills: 'Kills', deaths: 'Deaths', assists: 'Assists', mitigated_damage: 'MTGTD Damage', player_damage: 'PLYR Dmg', damage_taken: 'DMG TKN', gold: 'Gold', gpm: 'GPM', wards_placed: 'Wards', games_played: 'Games', structure_damage: 'STCTR DMG'}}
                  data={this.state.playerData && this.state.playerData.previous_seasons.map((info, index) =>
                    ({
                      "season": `${info.season}`,
                      "team": `${info.team}`,
                      "role": `${info.role}`,
                      "kills": `${info.kills}`,
                      "deaths": `${info.deaths}`,
                      "assists": `${info.assists}`,
                      "mitigated_damage": `${info.damage_mitigated}`,
                      "player_damage": `${info.player_damage}`,
                      "damage_taken": `${info.damage_taken}`,
                      "gold": `${info.gold_earned}`,
                      "gpm": `${info.gpm}`,
                      "wards": `${info.wards_placed}`,
                      "games": `${info.games_played}`,
                      "structure_damage": `${info.structure_damage}`,
                    })
                  )
                  }
                  showIndex={false}
                />
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}

export default withContext(Player);
