import styled from 'styled-components';

const PageWrapper = styled.div`
  padding: 1.3rem 0 0;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
  .c-StatsTable:last-of-type {
    margin-top: -2px;
  }
  .center-wrapper {
    background-color: var(--background);
    color: var(--text);
    margin: 0 auto;
    max-width: 156.6rem;
    padding: 0;
  }
  h1 {
    font-size: 2.4rem;
    margin-bottom: 5rem;
    text-transform: uppercase;
  }
  h2 {
    font-size: 1.8rem;
    padding: 1.6rem;
    text-align: left;
    text-transform: uppercase;
  }
  .hide {
    display: none;
  }
  .component-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    .header-copy {
      align-items: center;
      box-shadow: 0 0.3rem 0.6rem rgba(0,0,0,0.16);
      border: 1px solid var(--border);
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 142.2rem;
      .stats-btn {
        border-bottom: 1px solid;
        color: var(--text);
        cursor: pointer;
        margin-right: 2rem;
        @media only screen and (max-width: 768px) {
          background: #F1F1F1;
          border-bottom: none;
          padding: 1rem;
        }
      } 
    }
    .stats-tables, .additional-stats {
      width: 100%;
    }
    .additional-stats {
      max-width: 142.2rem;
      .additional-stats-container {
        margin-bottom: 1rem;
        width: 100%;
        @media only screen and (max-width: 1440px) {
          overflow-x: scroll;
        }
      }
      h2 {
        border: 1px solid var(--border);
        box-sizing: border-box;
        box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
      }
    }
    .stats-tables {
      max-width: 121rem;
      width: 100%;
      td {
        font-weight: 600;
      }
      .stats-container {
        @media only screen and (max-width: 768px) {
          overflow-x: scroll;
        }
      }
    }
    .footer-container {
      display: flex;
      justify-content: center;
      padding-bottom: 2rem;
      width: 100%;
      flex-wrap: wrap;
    }
  }

  .c-TopGodsCard {
    border-top: 1px solid #E1E1E1;
    margin: 2rem 0 0 2rem;
    @media only screen and (max-width: 1406px) {
      margin: 2rem 0 0 0;
    }
  }

  .c-RecentBuilds {
    margin-top: 2rem;
  }
  .c-GameStats {
    max-width: none;
  }
  
`;

export default PageWrapper;
